import * as React from "react"
import { Helmet } from "react-helmet"
import Layout_interne from "../layouts/Layout_interne"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {Link} from 'gatsby'
import Pagination from "../components/archivio/pagination"
import '../assets/scss/global.scss'
import '../templates/blog.scss'

const Blog = () => {

  const data = useStaticQuery(graphql`
    {    
      blogimg: file(relativePath: { eq: "blog.svg" }) {
                      relativePath
                      publicURL
                  } 
      allpost:  allWpPost {
                    nodes {
                    content
                    title
                    date
                    excerpt
                    author {
                        node {
                        name
                        }
                    }
                    Blog_campi {
                        tipoDiArticolo
                    }
                    featuredImage {
                        node {
                        localFile {
                            childImageSharp {
                              gatsbyImageData(width: 1920)
                            }
                        }
                        }
                    }
                    Blog_campi {
                        tipoDiArticolo
                    }
                    }
          }
    }
  `)
  var slugify = require('slugify')

    const { useState, useRef, useEffect } = React
    const [posts, setPosts] = useState(() => data.allpost.node)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostPerPage] = useState(5)
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirsPost = indexOfLastPost - postsPerPage
    let currentPosts = data.allpost.nodes.slice(indexOfFirsPost, indexOfLastPost)

    currentPosts.sort(function(a,b){
      return new Date(b.date) - new Date(a.date);
    })
    
    const topArchivio = useRef()
    const paginate = (pageNumber) => {
      setCurrentPage(pageNumber)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Homepage</title>
      </Helmet>
      <Layout_interne>
      <section className="maincontainer blog-lista" ref={topArchivio}>
      <div className="bloghead">
      <img width="200" height="200" class="image-risorse" src={data.blogimg.publicURL} />
      <h1>JamBlog!</h1>
      </div>
        <div className="wrappergrid-blog">
        {currentPosts.map((item)=>{

          let title = slugify(item.title, {replacement: '_', remove: /[*+~.()'"!:@?]/g,}) 
              title = title.toLowerCase()
          const immagineBlog = getImage(item.featuredImage.node.localFile)
         return (
           <div className="blog-single-card">
           <div className="blog-card-sx">
           <GatsbyImage image={immagineBlog} />
           </div>
           <div className="blog-card-dx">
         
          <h1>  <Link to={title}>{item.title}   </Link></h1>
       
          <div className={`bloglist tipoarticolo ${item.Blog_campi.tipoDiArticolo.toString().toLowerCase().replace(/\s+/g, '-')}`}>{item.Blog_campi.tipoDiArticolo}</div>
          <div className="author">{item.author.node.name}</div>
          <div dangerouslySetInnerHTML={{__html:item.excerpt}} />
          </div>
          </div>

          )
        })}
    
        
        </div>
        <Pagination postsPerPage={postsPerPage} totalPosts={data.allpost.nodes.length} paginate={paginate} topArchivio={topArchivio} />

        </section>
      </Layout_interne>
    </>
  )
}

export default Blog
